'use client'
import { Button } from '@/app/_primitives/Button'

export function SignOutButton() {
  return (
    <Button
      type="button"
      size="xs"
      onClick={async () => {
        try {
          const res = await fetch('/api/auth/login-as-stop', {
            credentials: 'include',
          })
          if (res.status === 200) {
            window.location.reload()
            window.location.href = '/admin/users'
          }
        } catch {}
      }}>
      나가기
    </Button>
  )
}
