import variant1 from '@/app/_primitives/styles/variant1.module.css'
import { cn } from '@/app/utils'

const variants = {
  variant1: variant1.loader,
}

type VariantConfigs = {
  variant1: {
    '--lv1-color': string
  }
}

export function Loader({
  variant,
  className,
  loaderClassName,
  variantConfig,
}: {
  variant: keyof typeof variants
  className?: string
  loaderClassName?: string

  variantConfig?: VariantConfigs[keyof VariantConfigs]
}) {
  return (
    <div
      className={cn(
        'w-fit h-fit flex flex-row items-center justify-center',
        className,
      )}>
      <span
        style={variantConfig as any}
        className={cn(variants[variant], loaderClassName)}
      />
    </div>
  )
}
