const colors = {
  frenchBlue: '#3366B6',
  butterScotch: '#FDCC53',
  milkyWhite: '#F7F4EF',
  white: '#FFFFFF',
  black: '#000000',
  cherry: '#CB311F',
  grey: '#D9DADB',
}

export const button = {
  frenchBlue: { bg: colors.frenchBlue, fg: colors.white, hover: '#1B4C98' },
  butterScotch: { bg: colors.butterScotch, fg: colors.black, hover: '#F5B517' },
  milkyWhite: { bg: colors.milkyWhite, fg: colors.black, hover: '#D1D6DC' },
  white: { bg: colors.white, fg: colors.black, hover: '#D1D6DC' },
  black: { bg: colors.black, fg: colors.white, hover: '#000000' },
  cherry: { bg: colors.cherry, fg: colors.white, hover: '#9F2618' },
  grey: {
    bg: colors.grey,
    fg: colors.black,
    hover: colors.grey,
  },
} as const
