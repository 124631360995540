'use client'

import { Loader } from '@/app/_primitives/Loader'
import buttonStyles from '@/app/_primitives/styles/button.module.css'
import { button } from '@/app/_primitives/theme'
import { cn } from '@/app/utils'
import { Slot } from '@radix-ui/react-slot'
import React from 'react'

// Note.
// [&_button:focus-visible]:ring-offset-[parent-bg]
// is used to remove ring-bg of the button

const themes = button

type Props = {
  color?: keyof typeof themes
  size?: 'sm' | 'xs' | 'lg' | 'default'
  variant?: 'filled' | 'outlined' | 'text'
  loading?: boolean

  type: 'button' | 'submit' | 'reset'
  asChild?: boolean
  disableHoverAnimation?: boolean
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>

function ButtonComponent(
  {
    children,
    color = 'frenchBlue',
    loading = false,
    variant = 'filled',
    size = 'default',
    disabled,
    className,
    asChild,
    disableHoverAnimation = false,
    ...props
  }: Props,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const Comp = asChild ? Slot : 'button'
  const classNames = cn(
    'button',
    `focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-4 focus-visible:ring-[#1D7AFC]`,
    'flex flex-row items-center gap-[6px] justify-center',
    'relative w-fit rounded-[100px] whitespace-nowrap cursor-pointer',
    {
      [`bg-[color:var(--bg)] text-[color:var(--fg)] [&_svg]:fill-[color:var(--fg)]`]:
        variant === 'filled',
      [`bg-transparent border border-[color:var(--bg)] text-[color:var(--bg)] [&_svg]:fill-[color:var(--bg)]`]:
        variant === 'outlined',
      'bg-transparent text-[color:var(--bg)] [&_svg]:fill-[color:var(--fg)]':
        variant === 'text',
      'border-2': variant === 'outlined' && size === 'lg',
      [`hover:text-[color:var(--fg)] [&_svg]:hover:fill-[color:var(--fg)] hover:delay-200 [&_svg]:hover:delay-200`]:
        !disabled && variant === 'outlined' && !disableHoverAnimation,
    },
    {
      'c-h6 px-[24px] py-[10px] h-72 [&_svg]:w-[24px] [&_svg]:h-[24px]':
        size === 'lg',
      'c-b3 px-[24px] py-[10px] h-56 [&_svg]:w-[20px] [&_svg]:h-[20px]':
        size === 'default',
      'c-b3 px-[24px] py-[10px] h-48 [&_svg]:w-[16px] [&_svg]:h-[16px]':
        size === 'sm',
      'c-b4 px-[24px] py-[10px] h-42 [&_svg]:w-[16px] [&_svg]:h-[16px]':
        size === 'xs',
      'px-0 py-0 h-fit': variant === 'text',
    },
    {
      'transition [&_svg]:transition active:scale-[98%]': !disabled,
      'cursor-not-allowed': disabled,
      'bg-tertiary text-txt-secondary [&_svg]:fill-txt-secondary':
        disabled && variant === 'filled',
      'border-tertiary text-txt-secondary [&_svg]:fill-txt-secondary':
        disabled && variant === 'outlined',
      'text-txt-secondary [&_svg]:fill-txt-secondary':
        disabled && variant === 'text',
    },
    {
      [`bg-[color:var(--bg)] border-none`]: loading && variant !== 'text',
    },
    {
      [buttonStyles.button]: !disableHoverAnimation && variant !== 'text',
    },
    className,
  )

  return (
    <>
      <style jsx>{`
        .button {
          --bg: ${themes[color].bg};
          --fg: ${themes[color].fg};
          --hover-bg: ${themes[color].hover};
        }
      `}</style>
      <Comp
        ref={ref}
        data-state={loading ? 'loading' : undefined}
        className={classNames}
        disabled={disabled}
        {...props}>
        {loading ? (
          <>
            <Loader
              variant={'variant1'}
              variantConfig={{
                '--lv1-color':
                  variant === 'text' ? themes[color].bg : themes[color].fg,
              }}
              className={
                'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'
              }
            />
            <div className={'invisible flex flex-row items-center gap-6'}>
              {children}
            </div>
          </>
        ) : (
          children
        )}
      </Comp>
    </>
  )
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  ButtonComponent,
)

Button.displayName = 'Button'
